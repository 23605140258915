import React from "react";
import { renderRTF, renderSecondaryRTF } from "./rich-text";
import getSpacing from "../utils/getSpacing";
import { Fade } from "react-awesome-reveal";


const SectionTitleCustom = ( { data } ) => {

  const spacing = getSpacing(data.topSpacing, data.bottomSpacing);

  const bg = data.backgroundColor === 'dark' ? 'bg-black text-white' : data.backgroundColor === 'white' ? 'text-black bg-white' : 'text-black bg-grey-300'

  const btn = data.backgroundColor === 'dark' ? 'text-black bg-white' : 'bg-black text-white' 

  return (
    <section className={`${spacing} ${bg}`} >
        <div className='container max-w-6xl'>
          <div className='flex flex-wrap -mx-4'>
            <div className='w-full md:w-1/2 md:mb-0 mb-8 px-4'>
              {renderSecondaryRTF(data.text)}
            </div>
            <div className='w-full md:w-1/2 px-4'>
              <Fade cascade direction="up" duration={400}>
                { data.el && data.el.map((item, idx, arr) => {
                  if (idx === arr.length - 1) {
                    return (
                      <div className='text-lg lg:text-xl font-light opacity-70'>
                        {item}
                      </div>
                    )
                  }
                  return (
                    <div className='mb-10 lg:mb-16 text-lg lg:text-xl font-light opacity-70'>
                      {item}
                    </div>
                  )
                })}
              </Fade>
            </div>
          </div>
        </div>
    </section>
  )
}

export default SectionTitleCustom;