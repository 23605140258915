import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { renderRTF } from "../rich-text";
import getSpacing from "../../utils/getSpacing";
import { Fade } from "react-awesome-reveal";

export default function Section({ data }) {
  const spacing = getSpacing(data.topSpacing, data.bottomSpacing)
  const bg = data.backgroundColor === 'dark' ? 'bg-black text-white' : data.backgroundColor === 'white' ? 'text-black bg-white' : 'text-black bg-grey-300'

  const sectionImage = getImage(data.image);

  return (
    <section className={`${spacing} ${bg} bg-[url('/cx-left-blue.svg')] bg-no-repeat bg-left-bottom bg-contain`}>
      { data.title && <div className="container max-w-5xl mb-12">
        <div
          className={`flex-wrap justify-center text-center flex -mx-4`}
        >
          <div className={`w-full md:w-10/12 px-4`}>
          {renderRTF(data.title)}
          </div>
        </div>
      </div>}
      <div className='container'>
        <div className='flex flex-wrap -mx-4'>
          <div className='w-full lg:w-6/12 px-4 lg:pr-10'>
            {data.image && (
              <GatsbyImage 
                image={sectionImage}
                alt={data.image.title}
              />
            )}
          </div>
          <div className='w-full lg:w-6/12 px-4'>
            
              <div className='flex flex-wrap -mx-4'>
                {
                  data.items.map(( item, idx ) => {
                    // const image = getImage(item.image)
                    return (
                        <div className='w-1/2 mb-12 px-4'>
                          <Fade delay={100 + (50 * idx)}>
                            <div className='w-14 h-14 mb-6'>
                              <img src={item.image.file.url} loading="lazy" width={72} height={72} alt={item.image.title} />
                            </div>
                          </Fade>
                          <Fade cascade delay={100 + (50 * idx)} duration={500} direction='up'>
                            {item.title && <p>{item.title}</p>}
                            {item.text && <p>{item.text.text}</p>}
                          </Fade>
                        </div>
                    )
                  })
                }
              </div>
            
          </div>
        </div>
      </div>
    </section>
  )
}