import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRTF } from "../rich-text";

export default function SectionHighlightsV2({ data }) {

  const textColor = data.backgroundColor === 'bg-secondary' ? 'text-white' : 'text-secondary';
  const bg = data.backgroundColor === 'bg-white' ? '' : `${data.backgroundColor}`;

  const sectionImage = getImage(data.image)

  return (
    <section className={` `}>
      <div className="container xl:max-w-7xl">
        <div className={`${bg} ${textColor}  rounded-20 overflow-hidden md:rounded-40 container mx-auto`}>
          <div className={`mx-auto relative pb-0`}>
            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-2/5 relative">
                <div className="aspect-w-4 aspect-h-3 lg:aspect-w-none lg:aspect-h-none h-full">
                  <div className="absolute inset-0  ">
                    <GatsbyImage
                      className="h-full w-full"
                      image={sectionImage}
                      alt={data.image.title}
                      objectFit="cover"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-3/5 flex justify-center items-center p-4 xl:px-14 xl:py-20 lg:py-16 lg:px-10">
                
                <div className="flex flex-wrap justify-start max-w-2xl  -mx-4">
                  <div className="px-4 w-full">
                    {renderRTF(data.text)}
                  </div>
                  {data.items.map((i,idx) => {
                    const heading = i.heading;
                    const headingDigit = heading.match(/\d*/)[0]
                    const headingSign = heading.match(/\D/)[0]
                    return (
                      <div key={`heading-${idx}`} className={`w-1/2 max-w-xs my-4 px-4`}>
                        <p className="h3 font-semibold">
                          {headingDigit}
                          <span className="text-accent">{headingSign}</span>
                        </p>
                        <p className="mt-4 mb-3 lg:mb-5 text-base md:text-md lg:text-lg">{i.subHeading}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
