import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { renderRTF } from "./rich-text";
import getSpacing from "../utils/getSpacing";
import { StaticImage } from "gatsby-plugin-image";
import { Fade } from "react-awesome-reveal";

export default function SectionMediaCustom({ data }) {
  const spacing = getSpacing(data.topSpacing, data.bottomSpacing);

  const bg =
    data.backgroundColor === "dark"
      ? "bg-black text-white"
      : data.backgroundColor === "white"
      ? "text-black bg-white"
      : "text-black bg-grey-300";

  const size = Array.from(data.items).length;

  if (data.type === "img") {
    return (
      <section className={`${spacing} ${bg}`}>
        <div className={`container max-w-5xl mx-auto`}>
          <div className="flex flex-wrap justify-center -mx-2 space-y-6">
            {data.text && <div className="w-full md:w-7/12 xl:w-7/12 px-2 text-center">
              {renderRTF(data.text)}
            </div>}
            <div className="w-full px-2">
              <div className="flex flex-wrap -mx-4">
                {data.items.map((item, idx) => {
                  const image= getImage(item.image)
                  return (
                    <div className='w-full md:w-1/2 mb-8 md:mb-0 px-4'>
                      <div className='flex flex-row -mx-3'>
                        <div className='w-auto px-3'>
                          <GatsbyImage 
                            className="w-14 h-14" 
                            image={image}
                            alt={item.image.title}
                          />
                        </div>
                        <div className='w-auto px-3'>
                          <p className='h4'>{item.title}</p>
                          <p className='opacity-80'>{item.text.text}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (data.type === "text") {
    return (
      <section className={`${spacing} ${bg}`}>
        <div className={`container max-w-6xl mx-auto`}>
          {data.text && <div className="flex flex-wrap justify-center -mx-2 space-y-6">
            <div className="w-full md:w-7/12 xl:w-7/12 px-2 text-center mb-8 lg:mb-12">
              {renderRTF(data.text)}
            </div>
          </div>}
        </div>
        <div className='container max-w-5xl'>
          <div className='flex flex-wrap justify-center -mx-4 transform lg:translate-x-12'>
            {data.items.map((i, idx) => {
              return (
                <div className={`${size < 3 ? 'md:w-1/2 w-full' : 'w-72' } px-4  my-8`}>
                  <Fade direction="right" delay={50 + (50 * idx)} duration={500}>
                    <div className='h-2 w-14 bg-blue-400 mb-4'>
                    </div>
                  </Fade>
                  <Fade cascade delay={75 + (75 * idx)} direction="up">
                    {i.title && <p className='h4 mt-6'>{i.title}</p>}
                    {i.text && <p className="opacity-80 mt-5">{i.text.text}</p>}
                  </Fade>
                </div>
              );
            })}

          </div>
        </div>
      </section>
    );
  }

  return null
}
