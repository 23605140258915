import React from "react";

const SectionItem = ({ data, type, hasWhiteText }) => {

  return (
    <div className={`w-full p-4`}>
      <div className={`bg-blue-card bg-opacity-95 rounded-lg hover:bg-white cursor-pointer rounded-xl p-3 -my-2`}>
        <div className="flex flex-row items-center justify-start -mx-2">
          {data.icon && <div className={`px-2 w-16 h-16`}>
            <div className="flex justify-center items-center w-full h-full">
              <img src={data.icon.file.url} alt={data.heading}  />
            </div>
          </div>}
          <div className="px-2 w-full">
            <div className="">
              <div className="text-sm md:text-base xl:text-lg font-semibold mb-1">{data.heading}</div>
              {data.subHeading && <p className="text-sm md:text-base xl:text-base font-inter">{data.subHeading}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionItem;