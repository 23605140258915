import React, { useRef } from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  variableWidth: true,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 3,
  centerPadding: "0px",
  centerMode: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 3,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 3,
        centerMode: true
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        // centerPadding: "80px",
        centerMode: true
      }
    }
  ]
}



const SectionImageCarousel = ({ data }) => {
  const { images } = data

  return (
    <section className={``}>
       <div className="mx-auto" style={{ maxWidth: "1620px"}}>
        <Slider {...settings}>
          {images.map((logo, idx) => {
            const image = getImage(logo)
            return (
              <div
                key={`slider-${idx}`}
                className="px-2 md:px-4 md:w-auto md:h-auto rounded-xl overflow-hidden"
              >
                <GatsbyImage
                  className="rounded-xl"
                  image={image} alt={logo.title}
                />
              </div>
            )})}
        </Slider>
      </div>
    </section>
  );
};

export default SectionImageCarousel;
