import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { renderRTF } from "../rich-text";
import getSpacing from "../../utils/getSpacing";
import { Fade } from "react-awesome-reveal";

export default function Section({ data }) {

  const spacing = getSpacing(data.topSpacing, data.bottomSpacing)
  const bg = data.backgroundColor === 'dark' ? 'bg-black text-white' : data.backgroundColor === 'white' ? 'text-black bg-white' : 'text-white bg-black'

  const bgGradient = data.backgroundColor === 'gradient' ? "bg-custom-1 bg-black" : "";

  const { height, width } = data.image.file.details.image;

  const aspectRatio = height / width;
  
  const sectionImage = getImage(data.image);

  if (data.textPosition === "center") {
    return (
      <section className={`${spacing} ${bg} relative`}>
        {data.backgroundColor === "gradient" ? (
          <div className="absolute inset-0 bg-custom-1 z-0"></div>
        ) : (
          ""
        )}

        {data.titleText && (
          <div className="container max-w-5xl relative z-10 mb-12">
            <div className={`flex-wrap justify-center text-center flex -mx-4`}>
              <div className={`w-full md:w-10/12 px-4`}>
                {renderRTF(data.titleText)}
              </div>
            </div>
          </div>
        )}
        {data.image && <div className="container max-w-2xl relative z-10">
          <div className={`w-full  px-4`}>
            <Fade>
              <GatsbyImage
                className="mx-auto rounded-2xl"
                image={sectionImage}
                alt={data.image.title}
              />
            </Fade>
          </div>
        </div>}
        {data.text && <div className={`container relative z-10 mt-12`}>
          <div className={`flex-wrap justify-center text-center flex -mx-4`}>
            <div className={`w-full md:w-8/12 px-4`}>
              {renderRTF(data.text)}
              {data.ctaText && data.ctaUrl && (
                <p>
                  <a href={data.ctaUrl} className="btn btn-accent">
                    {data.ctaText}
                  </a>
                </p>
              )}
            </div>
          </div>
        </div>}
        
    
      </section>
    );
  }


  return (
    <section className={`${spacing} ${bg}`}>
        <div className='container max-w-6xl'>
          <div className={`${data.textPosition === "left" ? "flex-row-reverse" : ""} flex flex-wrap justify-between items-center -mx-4`}>
            <div className={`${aspectRatio >= 0.8 ? "lg:w-5/12": "lg:w-6/12"} w-full md:w-1/2 px-4 mb-6 md:mb-0`}>

              {data.borderOffset ? (
                <div className="bg-cx relative rounded-2xl">
                  <div className="absolute inset-px bg-black rounded-2xl"></div>
                  <div className={`${data.textPosition === "left" ? "-translate-x-3 -translate-y-3" : "translate-x-3 -translate-y-3"}  transform`}>
                    <Fade delay={300}>
                      <GatsbyImage className="rounded-2xl" image={sectionImage} alt={data.image.title} />
                    </Fade>
                  </div>
                </div>
              ) : (
                <Fade delay={300}>
                  <GatsbyImage className="rounded-xl" image={sectionImage} alt={data.image.title} />
                </Fade>
              ) }
              
            </div>
            <div className='w-full md:w-1/2 px-4'>
              {renderRTF(data.text)}
                {data.ctaText && data.ctaUrl && (
                  <p>
                    <a href={data.ctaUrl} className="btn btn-accent">
                      {data.ctaText}
                    </a>
                  </p>
                )}
            </div>
          </div>
        </div>
    </section>
  )

}
