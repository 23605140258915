import React, { useRef } from "react";

import { renderRTF } from "./rich-text";
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/solid";
import getSpacing from "../utils/getSpacing";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Fade } from "react-awesome-reveal";

const SectionFeatureCarousel = ({ data }) => {
  const carouselWrap = useRef(null);

  const spacing = getSpacing(data.topSpacing, data.bottomSpacing);

  return (
    <section className={spacing}>
      <div className="container max-w-6xl mb-10 lg:mb-16">
        <div className="flex flex-wrap justify-between items-end -mx-4">
          <div className="w-auto max-w-md lg:max-w-xl w-auto flex-shrink px-4 mb-6 md:mb-0 text-white">
            {renderRTF(data.title)}
          </div>
          <div className="w-auto px-4 flex-shrink">
            <div className="flex flex-row items-center justify-end  -mx-2">
              <div className="w-auto px-2 hidden">{data.labelText}</div>
                <div className="w-auto px-2 flex flex-row -mx-1">
              <Fade direction="right" cascade duration={500} delay={1000}>
                  <div className="w-auto px-1">
                    <button
                      onClick={() => {
                        carouselWrap.current.scrollTo({
                          top: 0,
                          left: (carouselWrap.current.scrollLeft -= 200),
                          behavior: "smooth",
                        });
                      }}
                      type="button"
                      className="text-white w-12 h-12 rounded-full px-2 hover:opacity-60"
                    >
                      <ChevronLeftIcon className="h-8 w-8 mx-auto" />
                    </button>
                  </div>
                  <div className="w-auto px-1">
                    <button
                      onClick={() => {
                        carouselWrap.current.scrollTo({
                          top: 0,
                          left: (carouselWrap.current.scrollLeft += 200),
                          behavior: "smooth",
                        });
                      }}
                      type="button"
                      className="bg-black text-white w-12 h-12 rounded-full px-2 hover:opacity-60"
                    >
                      <ChevronRightIcon className="h-8 w-8 mx-auto" />
                    </button>
                  </div>
              </Fade>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container max-w-6xl">
        <div
          className="flex overflow-x-scroll hide-scroll-bar snap-x snap-mandatory -mx-2 lg:-mx-4"
          ref={carouselWrap}
        >
          <div className="flex flex-nowrap ">
            {
              data.items.map((item, idx) => {
                return (
                  <div
                    className={`${
                      idx === 0 ? `snap-start` : `snap-center`
                    } snap-always w-80 md:w-80 lg:w-96 px-2 lg:px-4 `}
                  >
                    <div className="text-white rounded-xl bg-grey-800 h-full p-6 relative overflow-hidden">
                      <Fade direction="up" cascade duration={500} delay={ 200 + (100 * idx)}>
                        <div className="w-16 h-16 mr-auto rounded-full">
                          <img src={item.image.file.url} loading="lazy" width={80} height={80} alt={item.title} />
                        </div>
                        <p className="mt-5 h4">{item.title}</p>
                        <p className="mt-4">{item.text.text}</p>
                      </Fade>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className="flex flex-w -mx-4 flex-nowrap"></div>
      </div>
    </section>
  );
};

export default SectionFeatureCarousel;
