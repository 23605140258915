import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function LogoStaticColumns({ data }) {
  const { images } = data

  return (
    <ul className="flex flex-wrap justify-center items-center w-full -mx-4">
      {images.map(logo => {
        const image = getImage(logo);
        return (
        <li key={logo.id} className="w-1/2 lg:w-1/3 mt-5 mb-5 text-center block px-4">
          <GatsbyImage
            style={{ height: "100%", width: "100%" }}
            imgStyle={{ objectFit: "contain" }}
            image={image} alt="logo"
          />
        </li>
      )})}
    </ul>
  )
}