import React from "react"
import LogoStatic from "./static"
import LogoSlider from "./slider"
import LogoStaticColumns from "./static-columns"

import { renderRTF } from "../rich-text"

export default function SectionLogos({ data }) {
  if (data.sectionType === "slider") {
    return (
      <section className={`relative overflow-hidden section-md`}>
        {data.titleText && <div className={`container mx-auto relative pb-0`}>
          <div className="flex flex-wrap justify-center -mx-4 space-y-6 md:space-y-0">
            <div className="w-full md:w-10/12 lg:w-7/12 px-2 text-center">
            {renderRTF(data.titleText)}
            </div>
          </div>  
        </div>}
          <LogoSlider data={data} />
      </section>
    )
  }

  if (data.sectionType === "static-column") {
    return (
      <section className={`relative section-xs`}>
        <div className={`container mx-auto relative pb-0`}>
          <div className="flex flex-wrap justify-center items-center xl:justify-between -mx-2 space-y-6 md:space-y-0">
            {data.titleText && <div className="w-full md:w-1/2 xl:w-5/12 px-2">
              {renderRTF(data.titleText)}
            </div>}
            <div className="w-full md:w-1/2 px-2">
              <LogoStaticColumns data={data} />
            </div>
          </div>
        </div>
      </section>
    )
  }


  return (
    <section className={`relative section-xs`}>
      <div className={`container mx-auto relative pb-0 space-y-6`}>
       {data.titleText && <div className="flex flex-wrap justify-center -mx-4 space-y-6 md:space-y-0">
          <div className="w-full md:w-10/12 lg:w-7/12 px-2 text-center">
            {renderRTF(data.titleText)}
          </div>
        </div>}
        <LogoStatic data={data} />
      </div>
    </section>
  )
}
