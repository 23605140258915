import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/common";
import SEO from "../components/Seo"

import Hero from "../components/hero/main";
import Section from "../components/content/section";
import SectionTitle from "../components/content/section-title";
import SectionWithCards from "../components/content/section-w-cards";
import SectionLogos from "../components/carousel/main";
import SectionMedia from "../components/content/section-media";
import SectionContact from "../components/content/section-contact";
import SectionHighlightsV2 from "../components/content/section-highlights-v2";
// import SubscribeSection from "../components/subscribe/email";
import SectionMultiRef from "../components/section-multi-ref";
import SectionWithItems from "../components/content/section-w-items";
import BlockquoteSection from "../components/content/section-blockquote";
import SectionSplashImage from "../components/content/section-splash-image";
import SectionWithCollapsible from "../components/content/section-w-collapsible";
import SectionLayeredImages from "../components/content/section-layered-images";
import SectionWithModules from "../components/content/section-w-modules";
import SectionWithCharts from "../components/section-w-charts";
import SectionFeatureCarousel from "../components/section-feature-carousel";
import SectionImageCarousel from "../components/section-image-carousel";
import SectionTitleColumns from "../components/section-title-columns";
import SectionTitleColumnsCards from "../components/section-title-columns-cards";
import SectionTitleColumnsSplashImage from "../components/section-title-columns-splash-image";
import CallToActionSection from "../components/call-to-action";
import SectionCustomOne from "../components/section-custom-one";
import SectionCustomShiftImages from "../components/section-custom-shift-images";
import SectionTitleCustom from "../components/section-title-custom";
import SectionMediaCustom from "../components/section-media-custom";
import SectionCustomText from "../components/section-custom-text";
import SectionTitleBackround from "../components/content/section-title-bg";
import SectionImageWithFeatures from "../components/content/section-image-features";

const CommonPageTemplate = ({ data }) => {
  const page = data.page.edges[0].node
  const pageBody = page.body

  return (
    <Layout>
      <SEO title={page.title} description={page.description} canonical />
      {/* space-y-20 md:space-y-24 lg:space-y-28 xl:space-y-36  */}

      {/* <h1>{ page.title }</h1>
      <h2>{ page.description }</h2> */}
      
      <div className="page-content">
        {pageBody.map((item) => {
          switch (item.__typename) {
            case "ContentfulHero":
              return <Hero data={item} key={`${item.id}`} />
            case "ContentfulSectionRegular":
              return <Section data={item} key={`${item.id}`} />
            case "ContentfulSectionTitle":
              return <SectionTitle data={item} key={`${item.id}`} />
            case "ContentfulSectionCallToAction":
              return <CallToActionSection data={item} key={`${item.id}`} />
            case "ContentfulSectionCards":
              return <SectionWithCards data={item} key={`${item.id}`} />
            case "ContentfulSectionLogos":
              return <SectionLogos data={item} key={`${item.id}`} />
            case "ContentfulSectionMedia":
              return <SectionMedia data={item} key={`${item.id}`} />
            case "ContentfulSectionHighlight":
              return <SectionHighlightsV2 data={item} key={`${item.id}`} />
            case "ContentfulSectionMultiRef":
              return <SectionMultiRef data={item} key={`${item.id}`} />
            case "ContentfulSectionColumnCards":
              return <SectionWithItems data={item} key={`${item.id}`} />
            case "ContentfulSectionBlockquote":
              return <BlockquoteSection data={item} key={`${item.id}`} />
            case "ContentfulSectionGetInTouch":
              return <SectionContact data={item} key={`${item.id}`} />
            case "ContentfulSectionSplashImageCustom":
              return <SectionSplashImage data={item} key={`${item.id}`} />
            case "ContentfulSectionCollapsibleCustom":
              return <SectionWithCollapsible data={item} key={`${item.id}`} />
            case "ContentfulSectionLayeredImages":
              return <SectionLayeredImages data={item} key={`${item.id}`} />
            case "ContentfulSectionModules":
              return <SectionWithModules data={item} key={`${item.id}`} />
            case "ContentfulSectionCharts":
              return <SectionWithCharts data={item} key={`${item.id}`} />
            case "ContentfulSectionFeaturesCarousel":
              return <SectionFeatureCarousel data={item} key={`${item.id}`} />
            case "ContentfulSectionImageCarousel":
              return <SectionImageCarousel data={item} key={`${item.id}`} />
            case "ContentfulSectionTitleColumns":
              return <SectionTitleColumns data={item} key={`${item.id}`} />
            case "ContentfulSectionTitleColumnsCards":
              return <SectionTitleColumnsCards data={item} key={`${item.id}`} />
            case "ContentfulSectionColumnTitleSplashImage":
              return <SectionTitleColumnsSplashImage data={item} key={`${item.id}`} />
            case "ContentfulSectionCustomOne":
              return <SectionCustomOne data={item} key={`${item.id}`} />
            case "ContentfulSectionCustomShiftImages":
              return <SectionCustomShiftImages data={item} key={`${item.id}`} />
            case "ContentfulSectionTitleCustom":
              return <SectionTitleCustom data={item} key={`${item.id}`} />
            case "ContentfulSectionMediaCustom":
              return <SectionMediaCustom data={item} key={`${item.id}`} />
            case "ContentfulSectionCustomText":
              return <SectionCustomText data={item} key={`${item.id}`} />
            case "ContentfulSectionTitleBackround":
              return <SectionTitleBackround data={item} key={`${item.id}`} />
            case "ContentfulSectionImageWithFeatures":
              return <SectionImageWithFeatures data={item} key={`${item.id}`} />
            default:
              return ''
          }
        })}
        {/* <div /> */}
      </div>
    </Layout>
  )
}

export default CommonPageTemplate

export const pageQuery = graphql`
  query CommonPageBySlug($slug: String!) {
    page: allContentfulPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          description
          name
          slug
          body {
          ... on ContentfulSectionGetInTouch {
            __typename
            id
            name
            bottomSpacing
            text {
              raw
            }
            topSpacing
          }
          ... on ContentfulSectionImageCarousel {
            __typename
            id
            name
            images {
              id
              file {
                details {
                  image {
                    width
                    height
                  }
                }
              }
              description
              title
              gatsbyImageData(
                placeholder: BLURRED
                height: 265
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          ... on ContentfulSectionBlockquote {
              __typename
              id
              blockquote: text
              author {
                avatar {
                  gatsbyImageData(
                    width: 192
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
                lastName
                firstName
                title
              }
            }

            ... on ContentfulHero {
              id
              __typename
              name
              extraText
              topSpacing
              bottomSpacing
              textPosition
              backgroundColor
              hasImageAsBackground
              ctaText
              ctaUrl
              cta {
                type
                ctaText
                ctaUrl
              }
              text {
                raw
              }
              image {
                title
                gatsbyImageData(
                  layout: CONSTRAINED 
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
                width
                height
              }
            }
            ... on ContentfulSectionRegular {
              __typename
              id
              name
              # ctaText
              # ctaUrl
              topSpacing
              bottomSpacing
              backgroundColor
              borderOffset
              image {
                file {
                  details {
                    image {
                      height
                      width
                    }
                  }
                }
                title
                gatsbyImageData(
                  width: 1200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              text {
                raw
              }
              titleText {
                raw
              }
              textPosition
            }
            ... on ContentfulSectionMedia {
              name
              __typename
              type
              backgroundColor
              topSpacing
              bottomSpacing
              imageBackground
              mediaItems {
                name
                image {
                  file {
                    url
                    details {
                      image {
                        height
                        width
                      }
                    }
                  }
                  title
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
                link
                heading
              }
              text {
                raw
              }
            }

            ... on ContentfulSectionCharts {
              __typename
              id
              name
              bottomSpacing
              topSpacing
              title {
                raw
              }
              labelText
              items {
                value
                text {
                  raw
                }
              }
            }

            ... on ContentfulSectionTitleColumns {
              __typename
              id
              name
              backgroundColor
              bottomSpacing
              topSpacing
              textLeft {
                raw
              }
              textRight {
                raw
              }
              ctaUrl
              ctaText
              items {
                subHeading
                heading
                icon {
                  file {
                    url
                  }
                  gatsbyImage(
                    width: 80
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                  title
                }
              }
            }

            ... on ContentfulSectionTitleColumnsCards {
              __typename
              id
              name
              backgroundColor
              bottomSpacing
              topSpacing
              textLeft {
                raw
                references {
                  ... on ContentfulAsset {
                    contentful_id
                    __typename
                    title
                    file {
                      url
                      fileName
                      details {
                        image {
                          height
                          width
                        }
                      }
                    }
                    gatsbyImageData(
                      width: 120
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              textRight {
                raw
              }
              # ctaUrl
              # ctaText
              cards {
                subHeading
                heading
                icon {
                  file {
                    url
                  }
                  gatsbyImage(
                    width: 80
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                  title
                }
              }
            }

            ... on ContentfulSectionColumnTitleSplashImage {
              __typename
              id
              name
              backgroundColor
              bottomSpacing
              topSpacing
              image {
                file {
                  details {
                    image {
                      height
                      width
                    }
                  }
                }
                title
                gatsbyImageData(
                  width: 1600
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              rightText {
                raw
              }
              leftText {
                raw
              }
              # ctaUrl
              # ctaText
              # items {
              #   subHeading
              #   heading
              #   icon {
              #     file {
              #       url
              #     }
              #     gatsbyImage(
              #       width: 80
              #       placeholder: BLURRED
              #       formats: [AUTO, WEBP, AVIF]
              #     )
              #     title
              #   }
              # }
            }


            ... on ContentfulSectionFeaturesCarousel {
              __typename
              id
              name
              items {
                image {
                  file {
                    url
                  }
                  gatsbyImage(
                    width: 80
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                  title
                }
                text {
                  text
                }
                title
              }
              labelText
              title {
                raw
              }
              topSpacing
              bottomSpacing
            }
            
          
            ... on ContentfulSectionCards {
              __typename
              id
              name
              topSpacing
              bottomSpacing
              cardCentred
              cardType
              text {
                raw
              }
              backgroundColor
             
              cards {
                heading
                subHeading
                icon {
                  file {
                    url
                  }
                  title
                }
                # link
              }
            }
            ... on ContentfulSectionTitle {
              __typename
              id
              name
              text {
                raw
                references {
                  ... on ContentfulAsset {
                    contentful_id
                    __typename
                    title
                    file {
                      url
                      fileName
                      details {
                        image {
                          height
                          width
                        }
                      }
                    }
                    gatsbyImageData(

                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              blueBlurBg
              ctaText
              ctaUrl
              topSpacing
              backgroundColor
              bottomSpacing
              maxWidth
            }

            # ... on ContentfulSectionCustomOne {
            #   __typename
            #   id
            #   name
            #   backgroundColor
            #   text {
            #     raw
            #   }
            #   image {
            #     gatsbyImageData(
            #       width: 600
            #       placeholder: BLURRED
            #       formats: [AUTO, WEBP, AVIF]
            #     )
            #     description
            #     title
            #   }
            # }

            ... on ContentfulSectionCustomShiftImages {
              id
              __typename
              name
              images {
                gatsbyImageData(
                  width: 500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
                title
                description
              }
            }

            ... on ContentfulSectionTitleCustom {
              id
              __typename
              name
              backgroundColor
              bottomSpacing
              topSpacing
              text {
                raw
              }
              el: items
            }

            ... on ContentfulSectionMediaCustom {
              id
              __typename
              name
              backgroundColor
              bottomSpacing
              topSpacing
              type
              items {
                image {
                  file {
                    url
                    details {
                      image {
                        height
                        width
                      }
                    }
                  }
                  gatsbyImageData(
                    width: 56
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                  title
                  description
                }
                title
                text {
                  text
                }
              }
            }

            ... on ContentfulSectionTitleBackround {
              __typename
              id
              name
              backgroundColor
              bottomSpacing
              image {
                title
                description
                gatsbyImageData(
                  width: 1200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              text {
                raw
              }
              topSpacing
            }
            
            ... on ContentfulSectionCustomText {
              __typename
              id
              name
              backgroundColor
              bottomSpacing
              topSpacing
              textMaxWidth
              containerMaxWidth
              text {
                raw
              }
            }

            ... on ContentfulSectionImageWithFeatures {
              __typename
              id
              name
              backgroundColor
              bottomSpacing
              topSpacing
              translateHorizontally
              # image {
              #   title
              #   description
              #   file {
              #       url
              #     }
              #   gatsbyImageData(
              #     width: 700
              #     placeholder: BLURRED
              #     formats: [AUTO, WEBP, AVIF]
              #   )
              # }
              items {
                image {
                  title
                  description
                  file {
                    url
                  }
                  gatsbyImageData(
                    width: 56
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
                text {
                  text
                }
                title
              }
            }

            ... on ContentfulSectionCallToAction {
              __typename
              id
              name
              backgroundColor
              topSpacing
              bottomSpacing
              ctaText
              ctaUrl
              isWide
              image {
                title
                gatsbyImageData(
                  width: 1200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
              text {
                raw
              }
            }

            ... on ContentfulSectionSplashImageCustom {
              id
              __typename
              topSpacing
              bottomSpacing
              backgroundColor
              image {
                title
                description
                gatsbyImageData(
                  width: 1200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`
