import React from "react";
import { renderSecondaryRTF } from "../rich-text";
import getSpacing from "../../utils/getSpacing";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Fade } from "react-awesome-reveal";

export default function SectionTitleBackround({data}) {

  const spacing = getSpacing(data.topSpacing, data.bottomSpacing)
  const bg = data.backgroundColor === 'dark' ? 'bg-black text-white' : data.backgroundColor === 'white' ? 'text-black bg-white' : 'text-black bg-grey-300'

  const sectionImage = getImage(data.image)

  return (
    <section className={`${bg} ${spacing} relative`}>
      <Fade duration={1500}>
        <div className="max-w-xl mx-auto">
          <div className='aspect-w-4 aspect-h-3'>
            <div className="absolute inset-0">
              <GatsbyImage
                style={{
                  height: "100%",
                  width: "100%"
                }}
                imgStyle={{ objectFit: "cover"}}
                image={sectionImage}
                alt={data.image.title}
              />
            </div>
          </div>
        </div>
      </Fade>
      <div className='absolute inset-0'>
        <div className='container max-w-4xl h-full flex flex-col justify-center items-center'>
          <Fade direction="up" delay={500}>
            <div className='text-center '>
              {renderSecondaryRTF(data.text)}
        
            </div>
          </Fade>
        </div>
      </div>
    </section>
  )
}
