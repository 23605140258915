import React from "react";

const ModuleItem = ({data}) => {
  return (
    <div className={`bg-blue-accord rounded-lg rounded-xl px-3 `}>
      <div className="flex flex-row items-center justify-start -mx-2">
        {data.icon && <div className={`px-2 w-24 h-24`}>
          <div className="flex justify-center items-center w-full h-full">
            <img src={data.icon.file.url} alt={data.heading}  />
          </div>
        </div>}
        <div className="px-2 w-full">
          <div className="">
            <p className="text-sm md:text-base xl:text-lg font-semibold">{data.heading}</p>
            {data.subHeading && <p className="text-sm md:text-base xl:text-base font-inter">{data.subHeading}</p>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default function ModuleItemRow({data}) {
  return (
    <div className={`container lg:mb-12 mb-8 lg:mt-10 mt-8`}>
      <div className="flex flex-wrap justify-center -mx-4 space-y-4 md:space-y-0">
        {data.items.map((i, idx, arr) => {
          return (
            <div className={`w-80 md:w-80 px-4`}
            >
              <ModuleItem data={i} type={data.itemType} />
            </div>
          );
        })}
      </div>
    </div>
  )
}