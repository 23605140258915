import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const MediaItem = ({ data }) => {
  const mediaImage = getImage(data.image);

  if (data.link) {
    return (
      <div className='w-full max-w-md md:w-1/3 lg:w-1/3 px-4 mb-8'>
        <div  className="bg-grey-800 p-4 block rounded-xl">
          <div className="aspect-w-16 aspect-h-9 rounded-lg">
            <div className="absolute inset-0">
              <GatsbyImage
                style={{ 
                  height: "100%", 
                  width: "100%" 
                }}
                imgStyle={{ objectFit: "cover"}}
                image={mediaImage} 
                alt={data.image.title} 
                className="rounded"
              />
            </div>
          </div>
          {/* <p className="mt-4 lg:mt-6 mb-3 lg:mb-5 text-base md:text-lg lg:text-xl">{data.heading}</p> */}
          <p className='mt-6 h-11'>{data.heading}</p>
          <p className=''><a href={data.link} className="btn border-white border hover:bg-cx hover:border-none">Learn More</a></p>
        </div>
      </div>
    )
  }
  
  return (
    <div className='w-full max-w-md md:w-1/3 lg:w-1/3 px-4 mb-10'>
      <div className="bg-grey-800 p-4 block">
        <div className="aspect-w-16 aspect-h-9">
          <div className="absolute inset-0">
            <GatsbyImage
              style={{ 
                height: "100%", 
                width: "100%" 
              }}
              imgStyle={{ objectFit: "cover"}}
              image={mediaImage} alt={data.image.title} 
            />
          </div>
        </div>
        <p className='mt-6'>{data.heading}</p>
        <p className='underline font-bold'>Learn More</p>
      </div>
    </div>
  )
}

export default MediaItem;

