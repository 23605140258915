import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export default function LogoSlider({ data }) {
  const { images } = data

  const maxSlides = 6;

  const currentSlides = Array.from(images).length

  const slidesNum = currentSlides > maxSlides ? maxSlides : currentSlides

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: slidesNum,
    slidesToScroll: 3,
    centerPadding: "0px",
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "80px",
          centerMode: true
        }
      }
    ]
  }
  
  return (
    <div className="max-w-7xl mx-auto">
      <Slider {...settings}>
        {images.map((logo, idx) => {
          const image = getImage(logo)
          return (
            <div
              key={`slider-${idx}`}
              className="sm:w-1/2 lg:w-1/6 xl:w-64 text-center flex justify-center items-center px-6"
            >
              <div className="aspect-w-2 aspect-h-1">
                <div className="absolute inset-0">
                  <GatsbyImage
                    style={{ 
                      height: "100%", 
                      width: "100%" 
                    }}
                    imgStyle={{ objectFit: "contain"}}
                    image={image} alt="logo"
                  />
                </div>
              </div>
            </div>
          )})}
      </Slider>
    </div>
  )
}