module.exports = {
  // darkMode: "class", // or "media" or "class"
  content: [
    "./src/**/*.js",
    "./src/**/*.jsx",
    "./src/**/*.ts",
    "./src/**/*.tsx",
  ],
  theme: {
    container: {
      center: true,
      padding: {
        DEFAULT: '1rem',
      }
    },
    fontFamily: {
      poppins: ['Poppins', 'sans-serif'],
    },
    fontSize: {
      "2xs": ["10px", "20px"],
      xs: ["12px", "20px"],
      sm: ["14px", "22px"],
      base: ["16px", "26px"],
      lg: ["18px", "32px"],    //lead h6
      xl: ["24px", "32px"],    //h5
      "2xl": ["32px", "40px"], //h4
      "3xl": ["40px", "48px"], //h3
      "4xl": ["48px", "56px"], //h2
      "5xl": ["56px", "64px"], //h1
    },
    screens: {
      'sm': '640px',
      // => @media (min-width: 640px) { ... }

      'md': '768px',
      // => @media (min-width: 768px) { ... }

      'lg': '1024px',
      // => @media (min-width: 1024px) { ... }

      'xl': '1280px',
      // => @media (min-width: 1280px) { ... }

      '2xl': '1536px',
      // => @media (min-width: 1536px) { ... }
    },
    
    extend: { 
      backgroundImage: {
        cx: 'linear-gradient(92.22deg, #8C7CFF 0.96%, #3D23F5 98.77%)',
        "custom-1": 'linear-gradient(180deg, #3C3752 0%, rgba(60, 55, 82, 0) 100%)',
      },
      animation: {
        'slide-up': 'slideup 200ms ease-in forwards',
      },
      keyframes: {
        slideup: {
          '0%': { transform: 'translateY(15px)', opacity: 0 },
          '100%': { transform: 'translateY(0)', opacity: 1 },
        }
      },
      
      borderRadius: {
        '3': "3px",
        '20': "20px",
        '10': "10px",
        '30': "30px",
        '40': "40px",
        '50': "50px"
      },
      borderWidth: {
        '3': '3px'
      },
      dropShadow: {
        image: "0px 4px 50px rgba(66, 86, 108, 0.1)",
      },
      colors: {
        white: {
          DEFAULT: "#ffffff",
        },
        grey: {
          DEFAULT: "#D9D9D9",
          300: "#FAFAFA",
          400: "#9895A5",
          500: "#D6D7DC",
          800: "#1E1C26",
          900: "#929292",
        },
        black: {
          DEFAULT: "#000000",
          100: "#3C3752",
        },
        blue: {
          DEFAULT: "#3D23F5",
          200: "#4229F5",
          400: "#6A55FF",
          600: "#8979FE",
        }
      },
      letterSpacing: {
        body: '0.0125rem'
      },
      lineHeight: {
        'extra-loose': '1.85',
        'extra-relaxed': '1.75',
      },
      maxWidth: {
        1600: "1600px",
        1920: "1920px",
      },
      opacity: {
        95: "95%"
      }
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    require('@tailwindcss/aspect-ratio'),
  ],
}
