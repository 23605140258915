import React from "react";

export default function ModuleImageRow({data}) {
  return (
    <div className={`container lg:mb-16 mb-12 relative`}>
      <div className="flex flex-wrap justify-between items-center w-full md:-mx-4 relative z-10">
        {data.images.map((logo, idx, arr) => {
          if (idx === 0 || idx === arr.length - 1) {
           return (<div key={logo.id} className="w-auto mt-5 mb-5 text-center block px-4">
              <img className="inline-block w-16 md:w-32" src={logo.file.url} alt="logo" />
            </div>)
          }
          return (
            <div key={logo.id} className="w-auto mt-5 mb-5 text-center block px-4">
              <img className="inline-block w-10 md:w-20" src={logo.file.url} alt="logo" />
            </div>
          )
        })}
      </div>
      <div className="absolute inset-x-20 top-1/2 h-px border-t border-dashed z-0" />
    </div>
  )
}