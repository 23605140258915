import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Fade } from "react-awesome-reveal";

export default function SectionCustomShiftImages({ data }) {

  const sectionImage1 = getImage(data.images[0])
  const sectionImage2 = getImage(data.images[1])
  const sectionImage3 = getImage(data.images[2])
  const sectionImage4 = getImage(data.images[3])

  return (
    <section className="bg-black">
      <div className='container lg:max-w-7xl md:max-w-2xl md:pt-12 lg:pt-20 transform lg:-translate-y-24 md:-translate-y-14'>
          <div className='flex flex-wrap justify-around -mx-4'>
            <Fade cascade direction="up" delay={300} duration={500}>
            <div className='w-full max-w-md sm:w-1/2 lg:w-64 px-4 transform lg:-translate-y-20 mb-6 lg:mb-0'>
                <div className='aspect-w-1 aspect-h-1 relative'>
                    <Fade direction="left" delay={150}>
                      <div className="absolute transform -translate-x-3 translate-y-3 border inset-0 border-blue-400 rounded-xl"></div>
                    </Fade>
                    <div className="absolute inset-0">
                      <GatsbyImage
                        style={{
                          height: "100%",
                          width: "100%"
                        }}
                        imgStyle={{ objectFit: "cover"}}
                        image={sectionImage1}
                        alt={data.images[0].title}
                        className="rounded-xl"
                      />
                    </div>
                </div>
            </div>
          
            <div className='w-full max-w-md sm:w-1/2 lg:w-64 px-4 transform lg:translate-y-10 mb-6 lg:mb-0'>
                <div className='aspect-w-1 aspect-h-1 relative'>
                  <Fade direction="up" delay={200}>
                    <div className="absolute transform translate-x-3 translate-y-3 border inset-0 border-blue-400 rounded-xl"></div>
                  </Fade>
                  <div className="absolute inset-0">
                    <GatsbyImage
                      style={{
                        height: "100%",
                        width: "100%"
                      }}
                      imgStyle={{ objectFit: "cover"}}
                      image={sectionImage2}
                      alt={data.images[1].title}
                      className="rounded-xl"
                    />
                  </div>
                </div>
            </div>
          
            <div className='w-full max-w-md sm:w-1/2 lg:w-64 px-4 transform lg:translate-y-20 mb-6 lg:mb-0'>
                <div className='aspect-w-1 aspect-h-1 relative'>
                  <Fade direction="up" delay={250}>
                    <div className="absolute transform -translate-x-3 translate-y-3 border inset-0 border-blue-400 rounded-xl"></div>
                  </Fade>
                  <div className="absolute inset-0">
                    <GatsbyImage
                      style={{
                        height: "100%",
                        width: "100%"
                      }}
                      imgStyle={{ objectFit: "cover"}}
                      image={sectionImage3}
                      alt={data.images[2].title}
                      className="rounded-xl"
                    />
                  </div>
                </div>
            </div>
            <div className='w-full max-w-md sm:w-1/2 lg:w-64 px-4 transform lg:-translate-y-20 mb-6 lg:mb-0'>
                <div className='aspect-w-1 aspect-h-1 relative'>
                  <Fade direction="right" duration={2000} delay={600}>
                    <div className="absolute transform translate-x-3 translate-y-3 border inset-0 border-blue-400 rounded-xl"></div>
                  </Fade>
                  <div className="absolute inset-0">
                    <GatsbyImage
                      style={{
                        height: "100%",
                        width: "100%"
                      }}
                      imgStyle={{ objectFit: "cover"}}
                      image={sectionImage4}
                      alt={data.images[3].title}
                      className="rounded-xl"
                    />
                  </div>
                </div>
            </div>
        
          </Fade>
        </div>
      </div>
    </section>
  )

}
