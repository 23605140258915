import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function LogoStatic({ data }) {
  const { images } = data
  return (
    <ul className="flex flex-wrap justify-center items-center w-full -mx-4">
      {images.map(logo => {

        const image = getImage(logo)
        
        return (
        <li key={logo.id} className="w-1/2 md:w-48 px-4 lg:w-2/12 my-5 text-center block">
          <div className="aspect-w-2 aspect-h-1">
            <div className="absolute inset-0">
              <GatsbyImage
                style={{ height: "100%", width: "100%" }}
                imgStyle={{ objectFit: "contain" }}
                image={image} alt="logo"
              />
            </div>
          </div>
        </li>
      )})}
    </ul>
  )
}