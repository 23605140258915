import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import SectionItem from "../item/main";
import { renderRTF } from "../rich-text";

export default function SectionWithItems({ data }) {

  const hasWhiteText = data.backgroundColor === 'bg-secondary' || data.backgroundColor === 'bg-accent'

  const sectionImage = getImage(data.image);

  return (
    <section className={` ${data.backgroundColor} pb-16 md:pb-20 lg:pb-28 px-4`}>
      <div className={`bg-secondary pt-12 md:pt-16 lg:pt-20 rounded-20 md:rounded-40 max-w-7xl mx-auto`}>
        {data.text && <div className="container max-w-5xl mb-12 text-white">
          <div
            className={`flex-wrap justify-center text-center flex -mx-4`}
          >
            <div className={`w-full md:w-10/12 px-4`}>
            {renderRTF(data.text)}
            </div>
          </div>
        </div>}
        <div className="xl:container px-4">
          <div
            className={`${hasWhiteText ? "text-white" : ""} flex-wrap justify-between items-center flex -mx-4`}
          >
            <div className={`lg:order-2 w-full lg:w-4/12 px-4 lg:-translate-x-10 -translate-y-6 lg:translate-y-0 transform relative z-10 lg:-my-8`}>
              <div className="flex flex-wrap -mx-4">
                {
                  data.items.map( (i) => <SectionItem hasWhiteText={hasWhiteText} data={i} type={data.itemType} />)
                }
              </div>
            </div>
            <div className={`lg:order-1 w-full lg:w-8/12 px-4 lg:translate-x-10 transform`}>
              <GatsbyImage 
                className="mx-auto filter drop-shadow-image rounded-2xl" 
                image={sectionImage}
                alt={data.image.title}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
