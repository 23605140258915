import React from "react";
import { renderRTF, renderSecondaryRTF } from "./rich-text";
import getSpacing from "../utils/getSpacing";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Fade } from "react-awesome-reveal";

const SubItems = ({data}) => {

  return (
      <div className='flex flex-wrap lg:-mx-4 -mx-2'>
        {data.map((item, idx) => {
          if (item.icon) {
            const image = getImage(data.image)
            return (
              <div className='w-1/2 mt-6 mb-8 px-4'>
      
                  <div className='w-10 h-10 mb-6'>
      
                    <img src={item.icon.file.url} loading="lazy" width={72} height={72} alt={item.icon.title} />
                  </div>
                  {data.heading && <p className="text-lg lg:text-xl">{item.heading}</p>}
                  {item.subHeading && <p className="text-sm">{item.subHeading}</p>}
      
              </div>
            )
          }
          return (
            <div className='w-1/3 lg:px-4 px-2'>
      
              <div className='text-lg lg:text-xl '><span className="text-gr">{item.heading}</span></div>
              <div className='text-sm'>{item.subHeading}</div>
            </div>
          )
        })}
      </div>
  )
}

const SectionTitleColumnsSplashImage = ( { data } ) => {

  const spacing = getSpacing(data.topSpacing, data.bottomSpacing);

  const bg = data.backgroundColor === 'dark' ? 'bg-black text-white' : data.backgroundColor === 'white' ? 'text-black bg-white' : 'text-white bg-black'

  const btn = data.backgroundColor === 'dark' ? 'text-black bg-white' : 'bg-black text-white' 
  const bgGradient = data.backgroundColor === 'gradient' ? "bg-custom-1 bg-black" : "";
  const sectionImage = getImage(data.image)

  return (
    <section className={`${spacing} ${bg}  relative`} >
      {data.backgroundColor === "gradient" ? (
        <div className="absolute inset-0 bg-custom-1 z-0"></div>
      ) : (
        ""
      )}
      <div className='container max-w-6xl relative z-10'>
        <Fade cascade direction="up" delay={300} duration={500}>
          <div className='flex flex-wrap -mx-4'>
            <div className='w-full md:w-1/2 px-4 mb-6 md:mb-0'>
              {renderSecondaryRTF(data.leftText)}
            </div>
            <div className='w-full md:w-1/2 px-4 space-y-4'>
              {data.rightText && <div className="text-grey-300 opacity-90">{renderRTF(data.rightText)}</div>}
              {data.items && <SubItems data={data.items} />}
              {data.ctaText && data.ctaUrl && (
                <p className="mt-8">
                  <a href={data.ctaUrl} className={`btn-primary w-40 text-center btn`}>
                    {data.ctaText}
                  </a>
                </p>
              )}
            </div>
          </div>
        </Fade>
      </div>
      {data.image && <div className="container max-w-6xl mt-10 md:mt-16 relative z-10">
          <Fade>
            <div className={`w-full  px-4`}>
              <GatsbyImage
                className="mx-auto rounded-2xl"
                image={sectionImage}
                alt={data.image.title}
              />
            </div>
          </Fade>
        </div>}
    </section>
  )
}

export default SectionTitleColumnsSplashImage;