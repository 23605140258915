import React from "react";
import HeroRegular from "./regular";
import HeroImageBackground from "./image-background";

const Hero = ({data}) => {

  if (data.hasImageAsBackground) {
    return <HeroImageBackground data={data} />
  }
  
  return (
    <HeroRegular data={data} />
  );
};

export default Hero;
