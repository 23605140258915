import React from "react";
import { renderTitle } from "../rich-text";
import getSpacing from "../../utils/getSpacing";
import { Fade } from "react-awesome-reveal";

export default function SectionTitle({data}) {

  const spacing = getSpacing(data.topSpacing, data.bottomSpacing)
  const bg = data.backgroundColor === 'dark' ? 'bg-black text-white' : data.backgroundColor === 'white' ? 'text-black bg-white' : 'text-black bg-grey-300'

  return (
    <section className={`${bg} ${spacing} relative`}>
      {data.blueBlurBg && <div className="absolute bg-blue blur-3xl opacity-10 -left-1/3 top-0 w-3/4 h-[70%] transform rotate-6 z-0"></div>}
      <Fade>
        <div className={`container title relative z-10`}
          style={{
            maxWidth: `${data.maxWidth}px`
          }}
        >
          <div
            className={`flex-wrap justify-center text-center flex -mx-4`}
          >
              <div className={` w-full px-4`}>
                {renderTitle(data.text)}
                {data.ctaText && data.ctaUrl && (
                  <p>
                    <a className="btn btn-lg min-w-[180px] btn-primary" href={data.ctaUrl}>
                      {data.ctaText}
                    </a>
                  </p>
                )}
              </div>
          </div>
        </div>
      </Fade>
    </section>
  )
}
