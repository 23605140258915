import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRTF } from "../rich-text";
import getSpacing from "../../utils/getSpacing";
import { Fade, Slide } from "react-awesome-reveal";

const CallToActionButton = ({item}) => {
  if (item.type === 'gradient') {
    return (<a href={item.ctaUrl} className='btn btn-lg min-w-[160px] btn-primary text-center'>
      {item.ctaText}
    </a>)
  }
  
  return (
    <a href={item.ctaUrl} className='btn btn-lg btn-outline text-center'>
      {item.ctaText}
    </a>
  )
}

const HeroRegular = ({ data }) => {


  const spacing = getSpacing(data.topSpacing, data.bottomSpacing)


  return (
    <header className={`relative ${spacing}`}>
      
      <Fade duration={2000}>
        <div className={`${!data.image && ` md:bg-[url('/cx-hero.svg')] bg-no-repeat bg-right-bottom inset-0 absolute` } z-0`}></div>
      </Fade>
      <div className="absolute  bg-blue blur-3xl opacity-10 -left-1/4 top-0 w-3/4 h-[70%] transform rotate-6 z-0"></div>
      
      <div className="container max-w-7xl relative z-10">
        <div
          className={`flex-wrap flex items-center md:justify-between justify-center -mx-4 space-y-6 md:space-y-0 -mx-4`}
        >            
          <div className={`lg:w-7/12 md:w-6/12 w-full px-4 max-w-4xl`}>
            
              <Fade direction="up" cascade duration={700} triggerOnce={true}>
                <div className="text-white">{renderRTF(data.text)}
                </div>
                
                  {data.cta && (
                    <div className="flex flex-wrap -mx-2 mt-8">
                      {data.cta.map(i => <div className="px-2 my-2 w-auto">
                        <CallToActionButton item={i}/>
                      </div>)}
                    </div>
                  )}
                
                {data.extraText &&  <div className='mt-8 text-grey-400'>
                  <p>{data.extraText}</p>
                </div>}
                
              </Fade>
          </div>
          <div className={`md:w-6/12 lg:w-5/12 w-full px-4 max-w-lg`}>
            { data.image && <div className="bg-cx rounded-xl relative p-px">
              <div className="absolute inset-px bg-black rounded-xl"></div>
              <div className="transform translate-x-2 translate-y-2">
                <GatsbyImage
                    image={data.image.gatsbyImageData}
                    layout="fullWidth"
                    objectFit="cover"
                    loading="eager"
                    className="h-full rounded-xl"
                    alt={data.image.title}
                  />
              </div>
            </div>
            }
            
          </div>
        </div>
      </div>
    </header>
  )
};

export default HeroRegular;
