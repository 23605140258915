import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderSecondaryRTF } from "./rich-text";
import getSpacing from "../utils/getSpacing";
import { Fade } from "react-awesome-reveal";

import siteConfig from "../utils/siteConfig";

const CallToActionSection = ({ data }) => {

    const ctaImage = getImage(data.image);
    const bg = data.backgroundColor === 'dark' ? 'bg-black text-white' : data.backgroundColor === 'white' ? 'text-black bg-white' : 'text-black bg-grey-300'

    const spacing = getSpacing(data.topSpacing, data.bottomSpacing);
  
    return (
      <div className={`${spacing} relative `}>
        <div className={`${bg} absolute inset-0 right-0 z-0`}></div>
        <div className={`${data.isWide ? "xl:container" : "max-w-6xl px-4"} mx-auto overflow-hidden relative z-10`}>
          <Fade cascade damping={0.7}>
            <div
              className="cta-bg bg-grey bg-opacity-80 flex flex-col justify-center bg-cover relative"
            >
              <div className="absolute inset-0 z-0">
                <GatsbyImage className=" h-full w-full" image={ctaImage} alt={data.image.title} />
              </div>
              <div className={`text-white container flex flex-col max-w-3xl justify-center h-full relative z-10 text-center`}>
                  <div className="self-center title-sm">
                    {renderSecondaryRTF(data.text)}
                    {data.ctaText && data.ctaUrl && (
                      <p>
                        <a href={data.ctaUrl}
                        target={`${ data.ctaUrl.indexOf(siteConfig.siteUrl) === -1 && data.ctaUrl.indexOf('http') === 0 ? '_blank' : '_self' }`}
                        className="btn btn-primary">
                          {data.ctaText}
                        </a>
                      </p>
                    )}
                  </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    )

}

export default CallToActionSection