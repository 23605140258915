module.exports = {
    siteUrl: `https://www.inflection.cx/`, // Site domain. Do not include a trailing slash!
    // siteUrl: https://www.inflection.cx/`, // Site domain. Do not include a trailing slash!
    
    postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: `Inflection CX`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `Inflection CX`, // This allows an alternative site description for meta data for pages.

    shareImageWidth: 1200, // Change to the width of your default share image
    shareImageHeight: 630, // Change to the height of your default share image
    image: '/og.png',
    siteimage: '/og.png',
    shortTitle: `Inflection CX`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#F2F2F2`, // Used for Offline Manifest
    themeColor: `#1B263A`, // Used for Offline Manifest
}
