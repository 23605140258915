import React from "react";
import { renderRTF } from "../../rich-text";

export default function ModuleTitle({data}) {
  return (
    <div className={`container title`}
      style={{
        maxWidth: `576px`
      }}
    >
      <div
        className={`flex-wrap justify-center text-center flex -mx-4`}
      >
        <div className={` w-full px-4`}>
          {renderRTF(data.text)}
          {data.ctaText && data.ctaUrl && (
            <p>
              <a className="btn btn-lg min-w-[160px] btn-primary" href={data.ctaUrl}>
                {data.ctaText}
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}