import React from "react";
import { renderRTF, renderSecondaryRTF } from "./rich-text";
import getSpacing from "../utils/getSpacing";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Fade } from "react-awesome-reveal";

const SubItems = ({data}) => {

  return (
      <div className='flex flex-wrap lg:-mx-4 -mx-2'>
        {data.map((item, idx) => {
          if (item.icon) {
            const image = getImage(data.image)
            return (
              <div className='w-1/2 mt-6 mb-8 px-4'>
                <Fade cascade direction="up" delay={100 * idx} duration={500}>
                  <div className='w-10 h-10 mb-6'>
      
                    <img src={item.icon.file.url} loading="lazy" width={72} height={72} alt={item.icon.title} />
                  </div>
                  {data.heading && <p className="text-lg lg:text-xl">{item.heading}</p>}
                  {item.subHeading && <p className="text-sm">{item.subHeading}</p>}
                  </Fade>
              </div>
            )
          }
          return (
            <div className='w-1/3 lg:px-4 px-2'>
              <Fade cascade direction="up" delay={100 * idx} duration={500}>
                <div className='text-lg lg:text-xl '><span className="text-gr">{item.heading}</span></div>
                <div className='text-sm'>{item.subHeading}</div>
              </Fade>
            </div>
          )
        })}
      </div>
  )
}

const SectionTitleColumns = ( { data } ) => {

  const spacing = getSpacing(data.topSpacing, data.bottomSpacing);

  const bg = data.backgroundColor === 'dark' ? 'bg-black text-white' : data.backgroundColor === 'white' ? 'text-black bg-white' : 'text-black bg-grey-300'

  const btn = data.backgroundColor === 'dark' ? 'text-black bg-white' : 'bg-black text-white' 

  return (
    <section className={`${spacing} ${bg} bg-[url('/cx-left.svg')]- bg-no-repeat bg-left-bottom bg-contain`} >
      <div className='container max-w-6xl'>
        <div className='flex flex-wrap -mx-4'>
          <div className='w-full md:w-1/2 px-4 mb-6 md:mb-0'>
            {renderSecondaryRTF(data.textLeft)}
          </div>
          <div className='w-full md:w-1/2 px-4 space-y-4'>
            {data.textRight && <Fade>
              <div className="text-grey-300 opacity-90">{renderRTF(data.textRight)}</div>
            </Fade>}
            {data.items && <SubItems data={data.items} />}
            {data.ctaText && data.ctaUrl && (
              <p className="mt-8">
                <a href={data.ctaUrl} className={`btn-primary w-40 text-center btn`}>
                  {data.ctaText}
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionTitleColumns;