import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { renderSecondaryRTF } from "./rich-text";


export default function SectionCustomOne({ data }) {

  const bg = data.backgroundColor === 'dark' ? 'bg-black text-white' : data.backgroundColor === 'white' ? 'text-black bg-white' : 'text-black bg-grey-300'

  const sectionImage = getImage(data.image)

  return (
    <section className={`${bg} pb-10`}>
      <div className="container max-w-4xl lg:px-10 bg-white bg-opacity-20">
        <div className="container">
          <div className="flex flex-wrap items-center justify-center -mx-4">
            <div className="w-full md:w-5/12 px-4 py-4">
              <div className="aspect-w-6 aspect-h-5 transform md:translate-y-4 -translate-y-4">
                <div className="absolute inset-0">
                  <GatsbyImage
                    style={{ 
                      height: "100%", 
                      width: "100%" 
                    }}
                    imgStyle={{ objectFit: "cover"}}
                    image={sectionImage} 
                    alt={data.image.title} 
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-7/12 px-4 text-white">
              {renderSecondaryRTF(data.text)}
            </div>
          </div>
        </div>
      </div>
    </section>
  )

}
