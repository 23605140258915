import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { renderRTF } from "../rich-text";

export default function SectionLayeredImages({ data }) {

    const hasWhiteText = data.backgroundColor === 'bg-secondary' || data.backgroundColor === 'bg-accent'
  const imageLeft = getImage(data.imageLeft)
  const imageRight = getImage(data.imageRight)
  
  return (
    <section className={` ${data.backgroundColor} pb-16 md:pb-20 lg:pb-28 `}>
      <div className={`pt-12 md:pt-16 lg:pt-20 rounded-20 md:rounded-40 max-w-7xl mx-auto`}>
        {data.titleText && <div className="container max-w-5xl mb-12">
          <div
            className={`flex-wrap justify-center text-center flex -mx-4`}
          >
            <div className={`w-full md:w-10/12 px-4`}>
            {renderRTF(data.titleText)}
            </div>
          </div>
        </div>}
        <div className="xl:container px-4">
          <div
            className={`${hasWhiteText ? "text-white" : ""} flex-wrap relative justify-between items-center flex -mx-4`}
          >
            <div className={`w-full md:w-9/12 px-4 relative lg:-my-8`}>
              <GatsbyImage 
                className="mx-auto filter drop-shadow-image rounded-2xl"
                image={imageLeft}
                alt={data.imageLeft.title}
                 />
            </div>
            <div className="md:absolute md:inset-0 w-full flex justify-center md:justify-end items-center -mt-24 md:mt-0">
              <div className={`w-9/12 md:w-6/12 px-4 relative z-10`}>
                <GatsbyImage 
                  className="mx-auto filter drop-shadow-image rounded-2xl" 
                  image={imageRight} 
                  alt={data.imageRight.title}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
