import React from "react";
import { renderRTF, renderSecondaryRTF } from "./rich-text";
import getSpacing from "../utils/getSpacing";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Card from "./card/main";



const SectionTitleColumnsCards = ( { data } ) => {

  const spacing = getSpacing(data.topSpacing, data.bottomSpacing);

  const bg = data.backgroundColor === 'dark' ? 'bg-black text-white' : data.backgroundColor === 'white' ? 'text-black bg-white' : 'text-white bg-black';

  const arr = data.cards;
  const rowClass =
    Array.from(arr).length === 5
      ? "md:w-1/3 xl:w-1/5 w-1/2"
      : Array.from(arr).length === 6
      ? "md:w-1/3 xl:w-1/6 w-full max-w-xs md:max-w-none"
      : Array.from(arr).length === 3
      ? "md:w-1/3 w-full "
      : Array.from(arr).length === 2
      ? "md:w-5/12 w-full"
      : Array.from(arr).length === 1
      ? "md:w-7/12 lg:w-6/12 w-full"
      : "md:w-1/4 w-full max-w-xs md:max-w-none";
  const cardClass =
    Array.from(arr).length === 5
      ? "p-4 md:p-4 lg:p-5"
      : Array.from(arr).length === 6
      ? "p-3 md:p-3 lg:p-5"
      : "p-3 md:p-4 lg:p-6";

  return (
    <section className={`${spacing} ${bg} relative`} >
      {data.backgroundColor === "gradient" ? (
          <div className="absolute inset-0 bg-custom-1 z-0"></div>
        ) : (
          ""
        )}
      <div className='container max-w-6xl relative z-10'>
        <div className='flex flex-wrap items-end -mx-4'>
          <div className='w-full md:w-1/2 px-4 mb-6 md:mb-0'>
            {renderSecondaryRTF(data.textLeft)}
          </div>
          <div className='w-full md:w-1/2 px-4 space-y-4'>
            {data.textRight && <div className="text-grey-400">{renderRTF(data.textRight)}</div>}
            {/* {data.items && <SubItems data={data.items} />} */}
            {data.ctaText && data.ctaUrl && (
              <p className="mt-8">
                <a href={data.ctaUrl} className={`btn-primary w-40 text-center btn`}>
                  {data.ctaText}
                </a>
              </p>
            )}
          </div>
        </div>
        
        {data.cards && <div className={`flex-wrap items-center mt-10 md:mt-12 flex -mx-4`}>
          <div className={`w-full  px-4`}>
            <div className="flex flex-wrap justify-center -mx-4">
              {data.cards.map((i, idx, arr) => {
                return (
                  <div className={`
                    ${rowClass} 
                    ${ data.cardCentred ? "text-center" : ""} w-1/2  my-4 px-2`}
                    key={idx}
                  >
                    <Card cardClass={cardClass} data={i} backgroundColor={'bg-black'} idx={idx} isLast={idx === arr.length - 1} type={'icon'} isCentered={''} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>}
      </div>
    </section>
  )
}

export default SectionTitleColumnsCards;