import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import FadeWrap from "../fade-wrap";

const BlockquoteSection = ({ data }) => {
  const authorImage = getImage(data.author.avatar)

  return (
    <section className={`section bg-black text-white`}>
      <div className="max-w-3xl mx-auto px-4 space-y-6">
        <FadeWrap>
          <div>
            <p className="h4 text-center">"{data.blockquote}"</p>
          </div>
          
          <div className="flex flex-col justify-center items-center max-w-md mx-auto">
          
            <div>
              <div className="w-20 h-20 relative rounded-full bg-grey mb-4 overflow-hidden">
                <GatsbyImage
                  className={`rounded-full w-full object-cover object-center`}
                  image={authorImage}
                  alt={`${data.author.firstName} ${data.author.lastName}`}
                />
              </div>
            </div>
          
          
            <div className='max-w-xs text-center text-grey-400'>
              <strong>{data.author.firstName} {data.author.lastName}</strong>{data.author.title}
            </div>
          </div>
        </FadeWrap>
          
      </div>
    </section>
  )
};

export default BlockquoteSection;
