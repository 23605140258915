import React from "react";
import { Fade } from "react-awesome-reveal";

const FadeWrap = ({children}) => {
  return (
    <Fade cascade triggerOnce={true} direction="up" delay={300}>
      {children}
    </Fade>
  )
}

export default FadeWrap;