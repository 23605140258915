import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Fade } from "react-awesome-reveal";

const MediaItemLg = ({ data, index }) => {
  const mediaImage = getImage(data.image);

  if (data.link) {
    return (
      <div className='w-full max-w-xs md:max-w-none md:w-5/12 px-4 mb-8 md:mb-0'>
        <Fade duration={1500} delay={index * 100}>
          <a href={data.link} target="_blank" rel="noreferrer" className="hover:opacity-80 block">
            <div className='aspect-w-1 aspect-h-1 hover:bg-black-100 bg-cx rounded-xl'>
              <div className="absolute inset-0">
                <GatsbyImage
                  style={{ 
                    height: "100%", 
                    width: "100%" 
                  }}
                  imgStyle={{ objectFit: "cover"}}
                  image={mediaImage} alt={data.image.title} 
                />
                
              </div>
              <div className="absolute inset-0 flex items-end p-4">
                <p className=' opacity-80 mt-6 font-light'>{data.heading}</p>
              </div>
            </div>
          </a>
        </Fade>
      </div>
    )
  }
  
  return (
    <div className='w-full max-w-xs md:max-w-none md:w-5/12 px-4 mb-8 md:mb-0'>
      <Fade duration={1500} delay={index * 100}>
        <div className='aspect-w-1 aspect-h-1 hover:bg-black-100 bg-cx rounded-xl'>
          <div className="absolute inset-0 flex flex-col items-start justify-between p-4">
            { data.image &&
              <img
                src={data.image.file.url}
                alt={data.image.title}
                layout="fullWidth" className={""}
                width={data.image.file.details.image.width}
                height={data.image.file.details.image.height}
                />
              }
            <p className=' opacity-80 mt-6 font-light'>{data.heading}</p>
          </div>
        </div>
      </Fade>
      
    </div>
  )
}

export default MediaItemLg;

