import React from "react";
import MediaItem from "../item/media";
import MediaItemBg from "../item/bg";
import MediaItemLg from "../item/large";

import { renderRTF } from "../rich-text";
import getSpacing from "../../utils/getSpacing";

export default function SectionMedia({ data }) {
  const spacing = getSpacing(data.topSpacing, data.bottomSpacing);

  const bg =
    data.backgroundColor === "dark"
      ? "bg-black text-white"
      : data.backgroundColor === "white"
      ? "text-black bg-white"
      : "text-black bg-grey-300";

  if (data.type === "background") {
    return (
      <section className={`${spacing} ${bg}`}>
        <div className={`container max-w-6xl mx-auto`}>
          <div className="flex flex-wrap justify-center -mx-2 space-y-6">
            {data.text && <div className="w-full md:w-7/12 xl:w-7/12 px-2 text-center">
              {renderRTF(data.text)}
            </div>}
            <div className="w-full px-2">
              <div className="flex flex-wrap -mx-4">
                {data.mediaItems.map((i, idx) => {
                  return <MediaItemBg data={i} index={idx} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (data.type === "large") {
    return (
      <section className={`${spacing} ${bg}`}>
        <div className={`container max-w-6xl mx-auto`}>
          {data.text && <div className="flex flex-wrap justify-center -mx-2 space-y-6">
            <div className="w-full md:w-7/12 xl:w-7/12 px-2 text-center mb-8 lg:mb-12">
              {renderRTF(data.text)}
            </div>
          </div>}
          <div className="w-full max-w-4xl mx-auto px-2">
            <div className="flex flex-wrap justify-center -mx-4">
              {data.mediaItems.map((i, idx) => {
                return <MediaItemLg data={i} index={idx} />;
              })}
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className={`${spacing} ${bg}`}>
      <div className={`container max-w-6xl mx-auto`}>
        <div className="flex flex-wrap justify-center -mx-2 space-y-6">
          { data.text && <div className="w-full md:w-7/12 xl:w-7/12 px-2 text-center">
            {renderRTF(data.text)}
          </div>}
          <div className="w-full px-2">
            <div className="flex flex-wrap -mx-4">
              {data.mediaItems.map((i) => {
                return <MediaItem data={i} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
