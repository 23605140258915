import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderSecondaryRTF } from "./rich-text";

const SectionMultiRef = ({ data }) => {

  const blockOne = data.blocks[0]
  const blockTwo = data.blocks[1]
  const blockThree = data.blocks[2]

  const sectionImage = getImage(blockOne.image);

  return (
      <section className={`section`}>
        <div className="max-w-7xl mx-auto px-4 xl:px-0">
          <div className="flex flex-wrap -mx-3 md:space-y-0 space-y-6">
            <div className="px-3 w-full md:w-5/12">
              <div className={`${blockOne.backgroundColor} rounded-20 lg:rounded-40 h-full`}>
                <div className="flex flex-col justify-stretch items-stretch -mx-4">
                  <div className="px-4">
                    <div className={`p-6 md:p-8 xl:p-10`}>
                      {blockOne.icon && <img alt={blockOne.icon.title} loading="lazy" width={80} height={80} className="w-20 ml-0 mb-8" src={blockOne.icon.file.url} />}
                      <div className={`
                        ${ blockOne.backgroundColor !== 'bg-blue-light'? 'text-white' : ''} title
                      `}>
                        {renderSecondaryRTF(blockOne.text)}
                        {blockOne.items && (
                          <div className="flex flex-wrap -mx-2">
                            {blockOne.items.map(el => {
                              return <div className="px-2 w-auto mt-4"><span className="md:text-lg block px-4 py-1.5 rounded-lg bg-white bg-opacity-10">{el.heading}</span></div>
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {sectionImage && <div className="px-4 w-full">
                    <GatsbyImage className="mx-auto" image={sectionImage} alt={blockOne.image.title} />
                  </div>}
                </div>
              </div>
            </div>
            <div className="px-3 w-full md:w-7/12">
              <div className="flex flex-wrap items-stretch -mx-4 h-full">
                <div className="w-full px-4">
                  <div className={`${blockTwo.backgroundColor} rounded-20 lg:rounded-40 h-full`}>
                    
                    <div
                      className={`p-6 md:p-8 xl:p-10`}
                    >
                      {blockTwo.icon && <img alt={blockTwo.icon.title} loading="lazy" width={80} height={80} className="w-20 ml-0 mb-8" src={blockTwo.icon.file.url} />}
                      <div className={`
                        ${ blockTwo.backgroundColor !== 'bg-blue-light' ? 'text-white' : ''} title 
                      `}>
                        {renderSecondaryRTF(blockTwo.text)}
                        {blockTwo.items && (
                          <div className="flex flex-wrap -mx-2">
                            {blockTwo.items.map(el => {
                              return <div className="px-2 w-auto mt-4"><span className="md:text-lg block px-4 py-1.5 rounded-lg bg-white bg-opacity-10">{el.heading}</span></div>
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {blockThree && (
                  <div className="w-full px-4 mt-6">
                    <div className={`${blockThree.backgroundColor} rounded-20 lg:rounded-40 h-full`}>
                      <div
                        className={`p-6 md:p-8 xl:p-10`}
                      >
                        {blockThree.icon && <img alt={blockThree.icon.title} loading="lazy" width={80} height={80} className="w-20 ml-0 mb-8" src={blockThree.icon.file.url} />}
                        <div className={`
                          ${ blockThree.backgroundColor !== 'bg-blue-light'? 'text-white' : ''} title 
                        `}>
                          {renderSecondaryRTF(blockThree.text)}
                          {blockThree.items && (
                            <div className="flex flex-wrap -mx-2">
                              {blockThree.items.map(el => {
                                return <div className="px-2 w-auto mt-4"><span className="md:text-lg block px-4 py-1.5 rounded-lg bg-white bg-opacity-10">{el.heading}</span></div>
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default SectionMultiRef
