import React, { useState, useRef, useEffect } from "react"; 

const AccordionItem = ({ data, idx }) => {
  
  const [isActive, setActive] = useState(idx === 0);
  const [height, setHeight] = useState(0);
  const ref = useRef(null)

  useEffect( () => {
    setTimeout(()=> {
      setHeight(ref.current.offsetHeight);
    }, 1000)
  },[])

  const updateHeight = () => {
    setHeight(ref.current.offsetHeight);
  }

  return (
    <div className={`${isActive ? 'bg-blue-accord' : ''} rounded-lg border border-blue-accord hover:border-white cursor-pointer text-white rounded-xl p-3 px-4 my-3`}>
      <button
        type="button"
        className="w-full md:text-lg font-medium block text-left"
        onClick={() => {
          updateHeight();
          setActive(!isActive)
        }}
      >
        {data.heading} 
      </button>
      <div className={`transition-height duration-500 ease-in-out overflow-hidden`}
        style={{
          height: `${ isActive ? height : 0 }px`
        }}
      >
        <div ref={ref}>
          <p className="text-sm md:text-base xl:text-base">{data.subHeading}</p>
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
