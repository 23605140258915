import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { renderRTF } from "../rich-text";
import AccordionItem from "../accordion-item";

export default function SectionWithCollapsible({ data }) {
  const hasWhiteText = data.backgroundColor === 'bg-secondary' || data.backgroundColor === 'bg-accent'

  const sectionImage = getImage(data.image);
  return (
    <section className={`${data.backgroundColor} section px-4`}>
      <div className={`bg-secondary section-sm rounded-20 md:rounded-40 max-w-7xl mx-auto`}>
        {data.titleText && <div className="container max-w-5xl mb-12 text-white">
          <div
            className={`flex-wrap justify-center text-center flex -mx-4`}
          >
            <div className={`w-full md:w-10/12 px-4`}>
            {renderRTF(data.titleText)}
            </div>
          </div>
        </div>}
        <div className="max-w-7xl mx-auto px-4 lg:px-8">
          <div
            className={`${hasWhiteText ? "text-white" : ""} flex-wrap justify-between items-center flex -mx-4`}
          >
            <div className={`lg:order-1 w-full lg:w-5/12 px-4 relative z-10`}>
              { data.items.map((item, idx) => <AccordionItem 
                data={item}
                idx={idx}
              />) 
              }
            </div>
            <div className={`lg:order-2 w-full lg:w-7/12 px-4`}>
              <GatsbyImage 
                className="mx-auto filter drop-shadow-image rounded-2xl"
                image={sectionImage} 
                alt={data.image.title}
               />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
