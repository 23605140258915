import React from "react";
import { useInView } from "react-intersection-observer";

const animateOptions = {
  threshold: [0.25],
  delay: 0,
  triggerOnce: false,
};

const OpacityWrap = ({ children }) => {
  const [wrapRef, wrapInView] = useInView(animateOptions);
  return (
    <div className={`${wrapInView ? "is-inview opacity-100" : "opacity-0"}  transition duration-1000 ease-in h-full`} ref={wrapRef}>
      { children }
    </div>
  );
};

export default OpacityWrap;
