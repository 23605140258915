import React from "react";
import {
  buildStyles,
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import { useInView } from 'react-intersection-observer';
import { renderTitle } from "./rich-text";
import OpacityWrap from "./opacity-wrap";

import getSpacing from "../utils/getSpacing";

import tailwindConfig from "../../tailwind.config";

const SectionWithCharts = ({ data }) => {
  const spacing = getSpacing(data.topSpacing, data.bottomSpacing);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: false
  });

  return (
    <OpacityWrap>
      <section className={spacing}>
        <div className="container text-center mb-8 text-white">
          {renderTitle(data.title)}
          <p className="uppercase font-bold text-base tracking-wider mt-12 text-grey-400">
            {data.labelText}
          </p>
        </div>
        <div className="container max-w-7xl" ref={ref}>
        {`Header inside viewport ${inView}.`}
          <div className="flex flex-wrap justify-center -mx-4">
            {data.items.map((item, idx) => {
              const endValue = Math.abs(item.value);
              const duration = (endValue / 100) * 1.5;
              return (
                <div className="w-full px-4 max-w-sm md:w-1/2 lg:w-1/4 mb-8 lg:mb-0">
                  <div className="">
                    <div className="mx-auto" style={{ width: 195, height: 195 }}>
                      {inView && <AnimatedProgressProvider
                        valueStart={0}
                        valueEnd={endValue}
                        duration={duration}
                        easingFunction={easeQuadInOut}
                      >
                        {(value) => {
                          const roundedValue = Math.round(value);
                          return (
                            <CircularProgressbarWithChildren
                              value={value}
                              text={`${
                                item.value > 0
                                  ? `+${roundedValue}%`
                                  : `-${roundedValue}%`
                              }`}
                              strokeWidth={11}
                              styles={buildStyles({
                                pathTransitionDuration: .5,
                                trailColor:
                                  tailwindConfig.theme.extend.colors.grey[800],
                                textColor:
                                  tailwindConfig.theme.extend.colors.white
                                    .DEFAULT,
                              })}
                            >
                              <svg className="h-0 w-0">
                                <defs>
                                  <linearGradient
                                    id="myGradient"
                                    gradientTransform="rotate(-15)"
                                  >
                                    <stop
                                      offset="0%"
                                      stopColor={
                                        tailwindConfig.theme.extend.colors
                                          .blue[200]
                                      }
                                    />
                                    <stop
                                      offset="100%"
                                      stopColor={
                                        tailwindConfig.theme.extend.colors
                                          .blue[600]
                                      }
                                    />
                                  </linearGradient>
                                </defs>
                              </svg>
                            </CircularProgressbarWithChildren>
                          );
                        }}
                      </AnimatedProgressProvider>}
      
                    </div>
                  </div>
                  <p className="text-center mt-5 text-white">
                    {renderTitle(item.text)}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </OpacityWrap>
  );
};

export default SectionWithCharts;
