import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Fade } from "react-awesome-reveal";

const MediaItem = ({ data, index }) => {
  const mediaImage = getImage(data.image);

  if (data.link) {
    return (
      <div className='w-full md:w-1/2 lg:w-1/3 px-2 mb-6'>
        <a href={data.link} target="_blank" rel="noreferrer" className="hover:opacity-80 block overflow-hidden rounded-xl">
          <Fade cascade delay={100 * index}>
            <div className='aspect-w-6 aspect-h-5 relative bg-grey bg-opacity-50'>
              <div className="absolute inset-0">
                <GatsbyImage
                  style={{ 
                    height: "100%", 
                    width: "100%" 
                  }}
                  imgStyle={{ objectFit: "cover"}}
                  image={mediaImage} alt={data.image.title} 
                />
              </div>
              
                <div className='p-5 inset-0 flex flex-col justify-between items-start'>
                  <div className='font-bold mb-2 text-lg'>{data.name}</div>
                  <p>{data.heading}</p>
                </div>
            </div>
          </Fade>
        </a>
      </div>
    )
  }
  
  return (
    <div className='w-full md:w-1/2 lg:w-1/3 px-2 mb-6'>
      <Fade cascade delay={100 * index}>
        <div className='aspect-w-6 aspect-h-5 relative bg-grey bg-opacity-50 overflow-hidden rounded-xl'>
          <div className="absolute inset-0">
            <GatsbyImage
              style={{
                height: "100%",
                width: "100%"
              }}
              imgStyle={{ objectFit: "cover"}}
              image={mediaImage} alt={data.image.title}
            />
          </div>
          <div className='p-5 inset-0 flex flex-col justify-between items-start'>
            <div className='font-bold mb-2 text-lg'>{data.name}</div>
            <p>{data.heading}</p>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default MediaItem;

