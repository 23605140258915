import React from "react";
import ModuleTitle from "./modules/title";
import ModuleImageRow from "./modules/image-row";
import ModuleItemRow from "./modules/item-row";

export default function SectionWithModules({ data }) {

    const sectionBody = data.sectionBody

  return (
    <section className={`bg-secondary text-white section`}>
      {sectionBody.map((item) => {
        switch (item.__typename) {
          case "ContentfulSectionTitle":
            return <ModuleTitle data={item} />
          case "ContentfulBlockImageRow":
            return <ModuleImageRow data={item} />
          case "ContentfulBlockItemRow":
            return <ModuleItemRow data={item} />
          default:
            return ''
        }
      })}
    </section>
  )
}
