import React from "react";
import { renderCustom } from "./rich-text";
import getSpacing from "../utils/getSpacing";



const SectionCustomText = ( { data } ) => {

  const spacing = getSpacing(data.topSpacing, data.bottomSpacing);

  const bg = data.backgroundColor === 'dark' ? 'bg-black text-white' : data.backgroundColor === 'white' ? 'text-black bg-white' : 'text-black bg-grey-300';

  return (
    <section className={`${spacing} ${bg}`} >
        <div className='container' style={{ maxWidth: `${data.containerMaxWidth}px`}}>
          <div style={{ maxWidth: `${data.textMaxWidth}px`}}>
            {renderCustom(data.text)}
          </div>
        </div>
    </section>
  )
}

export default SectionCustomText;