import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import getSpacing from "../../utils/getSpacing";

export default function SectionSplashImage({ data }) {

  const spacing = getSpacing(data.topSpacing, data.bottomSpacing)
  const bg = data.backgroundColor === 'dark' ? 'bg-black text-white' : data.backgroundColor === 'white' ? 'text-black bg-white' : 'text-black bg-grey-300'

  const sectionImage = getImage(data.image)

  return <section className={`${spacing} ${bg}`}>
    <div className="max-w-7xl px-4 mx-auto relative z-0 splash-image">
      <GatsbyImage
        image={sectionImage}
        alt={data.image.title}
      />
      <div className="absolute left-0 right-0 bottom-0 h-4 bg-black filter blur-3xl"></div>
    </div>
  </section>
}
